import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../templates/layout';
import BasePageContent from '../../../components/utils/BasePageContent';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions';
import { Alert, AlertTitle } from '@mui/material';

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: '',
      severity: '',
    };
  }

  componentDidMount() {
    const code = this.props.code;
    const { searchParams } = new URL(this.props.location.href);
    const decision = searchParams.get('decision');
    const otp = searchParams.get('otp');
    this.props.actions.handleEmailDecision(code, { otp, decision });
  }

  componentDidUpdate(prevProps) {
    const { decision } = this.props;
    if (decision && decision !== prevProps.decision) {
      let message;
      let severity;

      const { error, action } = decision;

      if (error) {
        switch (error) {
          case 'Authorization request has expired':
            message = 'Możliwość potwierdzenia wykonania operacji wygasła.';
            severity = 'error';
            break;

          case 'Action has been already handled':
            message = `Operacja z ${action.createdAt} została już obsłużona.`;
            severity = 'warning';
            break;

          default:
            message =
              'W procesie potwierdzania wykonania operacji wystąpił błąd.';
            severity = 'error';
            break;
        }
      } else {
        if (action.status === 'accepted') {
          severity = 'success';
          message = `Wykonanie operacji z ${action.createdAt} zostało zatwierdzone.`;
        } else {
          severity = 'warning';
          message = `Wykonanie operacji z ${action.createdAt} zostało odrzucone.`;
        }
      }
      this.setState({ loading: false, message, severity });
    }
  }

  render() {
    const { loading, message, severity } = this.state;
    return (
      <Layout >
        <BasePageContent
          loading={loading}
          title={this.props.decision?.action.description.type}
        >
          <Alert severity={severity}>
            <AlertTitle>{message}</AlertTitle>
            Możesz zamknąć tę kartę.
          </Alert>
        </BasePageContent>
      </Layout>
    );
  }
}


const mapStateToProps = state => ({
  decision: state.handle2FADecision,
  action: state.get2FAAction,
});

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export const Head = () => <title>Zatwierdzanie operacji | Zapłatomat</title>;

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
